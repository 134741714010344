import React from "react";
import { Box, BoxContainer, BoxOwlStageOuter, BoxStage, BoxWrapper, BtnA, BtnContainer, Image, ImageBox, PortfolioHeadingWrapper, PortfolioSectionContainer, PortfolioSectionWrapper, SpanUnderLine, TextDetailContainer, TextHeading, TextPara } from "./PortfolioSectionComponents";
import { AboutMeHeading, BrowseResumeButton, ButtonWrapper, Icon, Para, Span } from "../AboutmeSection/AboutmeComponent";
import { collection,getDocs,limit,orderBy,query } from "firebase/firestore";
import { firestore } from "../../firebase/config";
const Mould = [
    {date:'',src:'',title:'',category:'',description:''},
    {date:'',src:'',title:'',category:'',description:''},
    {date:'',src:'',title:'',category:'',description:''},
    {date:'',src:'',title:'',category:'',description:''},
    // category
    // contents
    // date
    // description
    // slug
    // src
    // title
]
function PortfolioSection(props){
    
    const [PortfoliosArray, setPortfoliosArray]=React.useState(Mould);


    const getPortfolio = async (setPortfoliosArray)=>{
        const docRef = collection(firestore, "Portfolio");
        const q = query(docRef, orderBy("date",'desc'), limit(4));
        const docsSnap = await getDocs(q);
        const temp = []
        docsSnap.forEach(doc => {
            temp.push(doc.data());
        })
        setPortfoliosArray(temp)


    }


    React.useEffect(()=>{
        getPortfolio(setPortfoliosArray);
    },[setPortfoliosArray])
    

    return(
        <PortfolioSectionContainer id="Portfolio">
            <PortfolioSectionWrapper className="container">

                <PortfolioHeadingWrapper className="row">
                    <div className="col-lg-6">
                        <AboutMeHeading><Span>My Portfolio</Span> </AboutMeHeading>
                        <Para>A TOP COMPUTER SCIENTIST, ENGINEER & RESEARCHER BASED IN INDIA</Para>
                        {/* <BtnContainer style={{marginTop:20}}>
                            <SpanUnderLine></SpanUnderLine>
                            <BtnA href="/Projects">
                                View All
                                <Icon/>
                            </BtnA>
                        </BtnContainer> */}
                        <ButtonWrapper style={{paddingTop:20}} className="row">
                            <BrowseResumeButton href="/Projects">
                                View All
                                <Icon />
                            </BrowseResumeButton>
                        </ButtonWrapper>
                    </div>
                </PortfolioHeadingWrapper>
                
                <BoxContainer className="owl-carousel owl-loaded  owl-drag" >
                    <BoxOwlStageOuter className="owl-stage-outer">

                        <BoxStage id="owl-id" className="owl-stage">
                            {PortfoliosArray.map((data,index)=>{
                                return(
                                    <BoxWrapper  className="owl-item col-lg-4"  >
                                        <Box  >
                                            <ImageBox >
                                                <Image src={data.src} />
                                            </ImageBox>
                                            <TextDetailContainer>
                                            <div>
                                                <div className="meta" style={{fontWeight:700}}>
                                                    <span className="text-theme" >{data.category}</span>
                                                </div>
                                                <TextHeading>{data.title}</TextHeading>
                                            </div>
                                            <TextPara>{data.description}</TextPara>
                                            <BtnContainer>
                                                <SpanUnderLine></SpanUnderLine>
                                                    <BtnA href={"/Projects/"+data.slug}>
                                                        View Project
                                                    <Icon/>
                                                </BtnA>
                                            </BtnContainer>

                                            </TextDetailContainer>
                                        </Box>
                                    </BoxWrapper>
                                )
                            })}
                        </BoxStage>
                    </BoxOwlStageOuter>

                </BoxContainer>



            </PortfolioSectionWrapper>
        </PortfolioSectionContainer>
    )
}

export default PortfolioSection;