import styled from 'styled-components';
import {Link } from 'react-scroll';
import {AiOutlineMenu} from 'react-icons/ai';

export const Nav = styled.header`
    background:${({scrollNav})=>(scrollNav?'#000':'transparent')};
    margin-top:-80px;
    height:72px;
    display:flex;
    justify-content:space-evenly; 
    padding:0.5rem calc((100vw-1000px)/2);
    font-family: "Rubik", sans-serif;
    z-index:10;
    top:0;
    position:sticky;
    width:100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media screen and (max-width:768px){
        justify-content:space-between;
    };
`
export const NavLink = styled(Link)`
    color:rgba(174,174,178,1);
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display:flex;
    align-items:center;
    text-decoration:none;
    padding:0 1rem;
    cursor: pointer;
    &:hover{
        transition:0.2s ease-in-out;
        transform:scale(1.15);
        text-decoration:none;
        color:#fff;
    }
    &.active{
        text-decoration:none;
        color:#fff;
    }
`
export const Bars = styled(AiOutlineMenu)`
    display:none;   

    color:#fff;
    align-self: center;
    @media screen and (max-width:768px){
        display:block;
        font-size:28px;
        cursor:pointer;
        margin: 15px;
    };
`
export const NavMenu = styled.div`
    display:flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    @media screen and (max-width:768px){
        display:none;
    }
`
export const Name = styled.p`
    display:flex;
    flex: 1;
    justify-content: center;
    align-self: center;
    font-size: 28px;
    color: white;
    letter-spacing: -0.8px;
    font-family: "Rubik", sans-serif;
    :hover{
        cursor: pointer;
    }
    @media screen and (max-width:768px){
        font-size:28px;
        cursor:pointer;
        margin: 15px;
        justify-content: flex-start;

    };
`