import styled from "styled-components";
import {BsArrowUpRight} from 'react-icons/bs';
export const AboutmeContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
 
`

export const AboutmeWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
`



export const AboutMeHeading = styled.h3`
    font-weight: 500;
    font-size: 35px;
    margin: 0 0 20px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    padding: 0 0 10px;
    text-transform: uppercase;
    color: #fff;
`

export const Para = styled.p`
    display: block;
    margin: 0;
    color: rgba(174,174,178,1);
`

export const AboutMeHeadingh4 = styled.h4`
    font-weight: 600;
    font-size: 30px;
    margin: 0 0 10px;
    color: #fff;
`

export const AboutMeHeadingh6= styled.h6`
    font-weight: 400;
    margin: 0 0 20px;
    color: #fff;
    font-size: 18px;
`
export const AboutmeTopSectionWrapper = styled.div`


`
export const Span = styled.span`
    ::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 2px;
        z-index: -1;
        background: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
        width: 50px;
    }
`

export const AboutmeSecondSectionWrapper = styled.div`

    @media screen and (max-width:768px){
        flex-direction: column;
        align-items: center;
    } 
`


export const AboutmeImageWrapper = styled.div`
    aspect-ratio: 1;    
    padding: 7px;
    background-image: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
    background-color: black;
    margin: 20px;
    @media screen and (max-width:768px){
        width: calc(100% - 40px);
    }
    
 
`

export const AboutmeImage = styled.img`
    width: 100%;
    height: 100%;
    background-color: black;
    object-fit:cover;
    max-width: 100%;
`



export const AboutmeDetailsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding:20px;
    justify-content: center;
`

export const ButtonWrapper = styled.div`
    padding-top: 40px;
`

export const BrowseResumeButton = styled.a`

    padding: 0 25px;
    line-height: 46px;
    background: none;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    z-index: 1;
    overflow: hidden;
    border-radius: 50px;
    transition: ease all 0.35s;
    width: fit-content;
    background-image: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
    color: #fff;
    background-size: 200% 100%;
    background-position: left center;
    background-position: 0 0;
    cursor: pointer;
    :hover{
        color: #fff;
        background-position: 100% 0;
        text-decoration: none;
    }
`
export const Icon = styled(BsArrowUpRight)`
    font-size: 16px;
    margin-left: 8px;
    align-self: center;
`