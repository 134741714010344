import styled from "styled-components";

export const Container = styled.div`
    background-color: #000;
    z-index:1;
    bottom:0;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    position: fixed;
    overflow: hidden;   
    width:100%;
`

export const RightEffect= styled.div`
    position: fixed;
    top: 50px;
    right: 0;
    bottom: auto;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: #f64f59;
    -webkit-filter: blur(100px);
    filter: blur(100px);
    opacity: 0.25;
    pointer-events: none;
    z-index: -1;
    @media screen and (max-width:768px){
        width: 200px;
        height: 200px;
    }

`
export const LeftEffect= styled.div`
    position: fixed;
    bottom: 50px;
    left: 0;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: #c471ed;
    -webkit-filter: blur(100px);
    filter: blur(100px);
    opacity: 0.25;
    pointer-events: none;
    z-index: -1;
    @media screen and (max-width:768px){
        width: 200px;
        height: 200px;
    }
`

