import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyByA45mvvJzSMVrSoS1FWQKazBdHJAtgJY",
  authDomain: "venishpatidar-aea3b.firebaseapp.com",
  projectId: "venishpatidar-aea3b",
  storageBucket: "venishpatidar-aea3b.appspot.com",
  messagingSenderId: "1066333594677",
  appId: "1:1066333594677:web:5ca076d7cc341794cd7d7c"
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const database = getDatabase(app);