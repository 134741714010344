import React from "react";
import { AboutMeHeading, Icon, Para } from "../AboutmeSection/AboutmeComponent";
import { CoreValueHeading } from "../CoreValuesSection/CoreValueComponent";
import { Box, BtnA, BtnContainer, Image, ImageBox, SpanUnderLine, TextDetailContainer, TextHeading, TextPara } from "../PortfolioSection/PortfolioSectionComponents";
import { BoxContainer, BoxWrapper, CategoryContainer, CategoryLi, CategoryliBtn, CategoryList, ProjectPageContainer, ProjectPageHeading, ProjectPageWrapper } from "./ProjectPageComponent";
import { collection,getDocs,query, where } from "firebase/firestore";
import { firestore } from "../../firebase/config";


function ProjectsMainPage(){

    const [PortfoliosArray, setPortfoliosArray]=React.useState([]);
    const [selectedCategory,setSelectedCategory]=React.useState('All');
    const [AllCategories, setAllCategories] = React.useState(['All']);

    const changeCategory = async (selectedCategory)=>{
        console.log("in");  
        const docRef = collection(firestore, "Portfolio");
        var q;
        if(selectedCategory==='All'){
            q = collection(firestore, "Portfolio");
        }
        else if(selectedCategory==='Other'){
            q = query(docRef, where('category','not-in',AllCategories));
        }
        else{
            q = query(docRef, where('category',"==",selectedCategory));
        }
        const docsSnap = await getDocs(q);
        const temp = []
        docsSnap.forEach(doc => {
            temp.push(doc.data());
        })
        setPortfoliosArray(temp)
        setSelectedCategory(selectedCategory)
    }
    const getPortfolio = async (setPortfoliosArray)=>{
        const q = collection(firestore, "Portfolio");
        const docsSnap = await getDocs(q);
        const temp = []
        docsSnap.forEach(doc => {
            temp.push(doc.data());
        })
        setPortfoliosArray(temp)
        document.title="Venish Patidar - Portfolio";
        document.querySelector('meta[name="description"]')
        .setAttribute('content', "My latest projects");

    }

    const getCategories =async ()=>{
        const docsSnap = await getDocs(collection(firestore, "category"));
        const temp = ['All']
        docsSnap.forEach(doc => {
            temp.push(doc.data()?.name);
        })
        setAllCategories(temp)
    }

    React.useEffect(()=>{
        getPortfolio(setPortfoliosArray);
        getCategories(setAllCategories);
    },[setPortfoliosArray,setAllCategories])


    return(
        <ProjectPageContainer  style={{minHeight:window.innerHeight}} id="All projects">
            <ProjectPageWrapper className="container">
                <ProjectPageHeading className="row">
                    <div className="col-lg-6">
                        <CoreValueHeading><span className="text-theme">Portfolio</span></CoreValueHeading>
                        <br/>
                        <AboutMeHeading >My Latest Projects</AboutMeHeading>
                        <Para>Explore my latest projects in web development, AI applications, mobile applications, and cutting-edge AI research.</Para>
                    </div>  
                    <CategoryContainer className="row">
                        <CategoryList>
                            {AllCategories.map((data,index)=>{
                                return(
                                    <CategoryLi key={data}>
                                        <BtnContainer onClick={()=>{
                                            changeCategory(data)
                                        }}>
                                            <SpanUnderLine></SpanUnderLine>
                                            <CategoryliBtn active={data===selectedCategory}>
                                                {data}
                                            </CategoryliBtn>
                                        </BtnContainer>
                                    </CategoryLi>
                                )
                            })}

                            


                        </CategoryList>
                    </CategoryContainer>
                </ProjectPageHeading>



                <BoxContainer className="container">
                    <BoxWrapper className="row"  >
                    {PortfoliosArray.map((data,index)=>{
                        return(
                            <BoxWrapper key={index} className="col-lg-4"  >
                                <Box>
                                    <ImageBox style={{}}>
                                        <Image src={data.src} />
                                    </ImageBox>
                                    <TextDetailContainer>
                                        <div>
                                        <div className="meta" style={{fontWeight:700}}>
                                            <span className="text-theme" >{data.category}</span>
                                        </div>
                                        <TextHeading>{data.title}</TextHeading>
                                        </div>
                                        <TextPara>{data.description}</TextPara>
                                        <BtnContainer >
                                            <SpanUnderLine></SpanUnderLine>
                                            <BtnA href={"/Projects/"+data.slug}>
                                                View Project
                                                <Icon/>
                                            </BtnA>
                                        </BtnContainer>
                                    </TextDetailContainer>
                                </Box>
                            </BoxWrapper>
                        )
                    })}

                    </BoxWrapper>
                </BoxContainer>


            </ProjectPageWrapper>
        </ProjectPageContainer>
    )
}

export default ProjectsMainPage;