import React from "react";
import { AboutmeContainer, AboutmeDetailsWrapper, AboutMeHeading, AboutMeHeadingh4, AboutMeHeadingh6, AboutmeImage, AboutmeImageWrapper, AboutmeSecondSectionWrapper, AboutmeTopSectionWrapper, AboutmeWrapper, BrowseResumeButton, ButtonWrapper, Icon, Para, Span } from "./AboutmeComponent";

function AboutMeSection(){
    const [screenSize, getDimension] = React.useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      });
    const setDimension = () => {
        getDimension({
          dynamicWidth: window.innerWidth,
          dynamicHeight: window.innerHeight
        })
    }

    React.useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })



    }, [screenSize])
    return(
        <AboutmeContainer id="About me" style={{height:screenSize.dynamicHeight>968?screenSize.dynamicHeight:""}}>
            <AboutmeWrapper className="container">

                <AboutmeTopSectionWrapper className="row ">
                    <div className="col-lg-6">
                        <AboutMeHeading><Span>About me</Span> </AboutMeHeading>
                        <Para style={{textTransform:"uppercase"}}>A top computer scientist and engineer with a specialization in artificial intelligence based in India</Para>
                    </div>
                </AboutmeTopSectionWrapper>

                <AboutmeSecondSectionWrapper className="row ">
                    <AboutmeImageWrapper  className="col-lg-6">
                        <AboutmeImage src="https://firebasestorage.googleapis.com/v0/b/venishpatidar-aea3b.appspot.com/o/WhatsApp%20Image%202023-07-13%20at%2021.33.27.jpg?alt=media&token=6345e726-0548-4bb0-924a-12b8adefdc51" />
                    </AboutmeImageWrapper>
                    <AboutmeDetailsWrapper className="col-lg-6">
                        <AboutMeHeadingh4>I'm Venish Patidar</AboutMeHeadingh4>
                        <AboutMeHeadingh6>AN <span className="text-theme">AI RESEARCHER</span>, COMPUTER SCIENTIST & ENGINEER BASED IN <span className="text-theme">INDIA</span></AboutMeHeadingh6>

                        <Para >I think, sketch plans, and convert my imagination into existence. What helps me succeed is a strong belief in myself and a refusal to give up.</Para>
                        
                        <div class="row" bis_skin_checked="1">
                            <div class="col-md-6" bis_skin_checked="1">
                                <div class="media" bis_skin_checked="1">
                                    <label>Birthday</label>
                                    <p>13th of June 1999</p>
                                </div>
                                <div class="media" bis_skin_checked="1">
                                    <label>Age</label>
                                    <p>24 Yr</p>
                                </div>
                                <div class="media" bis_skin_checked="1">
                                    <label>Residence</label>
                                    <p>India</p>
                                </div>
                                <div class="media" bis_skin_checked="1">
                                    <label>Address</label>
                                    <p>Madhya Pradesh, India</p>
                                </div>
                            </div>
                            <div class="col-md-6" bis_skin_checked="1">
                                <div class="media" bis_skin_checked="1">
                                    <label>E-mail</label>
                                    <p>venishpatidar@gmail.com</p>
                                </div>
                                <div class="media" bis_skin_checked="1">
                                    <label>Phone</label>
                                    <p>+91-831-9874206</p>
                                </div>
                                <div class="media" bis_skin_checked="1">
                                    <label>Discord</label>
                                    <p>venish#0673</p>
                                </div>
                                <div class="media" bis_skin_checked="1">
                                    <label>Freelance</label>
                                    <p>Available</p>
                                </div>
                            </div>
                        </div>
                        <ButtonWrapper className="row">
                            <BrowseResumeButton href="./Resume">
                                Browse Resume
                                <Icon />
                            </BrowseResumeButton>
                        </ButtonWrapper>

                    </AboutmeDetailsWrapper>
                </AboutmeSecondSectionWrapper>

            </AboutmeWrapper>
        </AboutmeContainer>
    )
}
export default AboutMeSection;