import React from "react";
import { AboutMeHeading, Para, } from "../AboutmeSection/AboutmeComponent";
import { BoxDetailWrapper, BoxHeading, BoxWrapper, CoreValueBox, CoreValueContainer, CoreValueHeading, CoreValueHeadingWrapper, CoreValueWrapper,  IconContainer, SecondContainer } from "./CoreValueComponent";
import {IoIosApps} from 'react-icons/io';
import {FcNightLandscape,FcIdea,FcBullish,FcSafe,FcBinoculars} from 'react-icons/fc';
const mould = [ 
    {icon:<FcNightLandscape/>,title:'Curiosity',description:'The innate trait that drives me to explore the unknown.'},
    {icon:<FcBinoculars/>,title:'Research',description:'I research to invent the technology that benefits humankind and to satiate my curiosity.'},
    {icon:<FcIdea/>,title:'Creativity',description:'A personal trait that helps to explore anything with multiple views and ideas.'},
    {icon:<FcBullish/>,title:'Innovation',description:' Research with the help of creativity to invent new methods, algorithms, and technologies.'},
    {icon:<FcSafe/>,title:'Optimism',description:'A never ending stream of positivity I look at everything from a positive perspective.'},
    {icon:<IoIosApps/>,title:'Hard work',description:'A never quiting mindset—If there is a will, there is a way'},

]


function CoreValue(){
    return(
        <CoreValueContainer id="Values">
            <CoreValueWrapper className="container">
                <CoreValueHeadingWrapper className="row">
                    <div className="col-lg-6">
                        <CoreValueHeading><span className="text-theme">Core Values</span></CoreValueHeading>
                        <br/>
                        <AboutMeHeading>Core values that drive my work</AboutMeHeading>
                        <Para>Core values are traits or qualities that represent an individual's highest priorities, deeply held beliefs, and core, fundamental driving forces.</Para>
                    </div>
                </CoreValueHeadingWrapper>

                <SecondContainer className="row gy-4">
                    {mould.map((data,index)=>{
                        return(
                            <BoxWrapper className="col-sm-6 col-lg-4">
                                <CoreValueBox>
                                    <IconContainer>
                                        {data.icon}
                                    </IconContainer>
                                    <BoxDetailWrapper className="col">
                                        <BoxHeading>
                                            {data.title}
                                        </BoxHeading>
                                        <Para>
                                            {data.description}
                                        </Para>
                                    </BoxDetailWrapper>
                                </CoreValueBox>
                            </BoxWrapper>
                        )
                    })}
                    
                </SecondContainer>

            </CoreValueWrapper>
        </CoreValueContainer>
    )
}

export default CoreValue;