import React from "react";
import {  Para } from "../AboutmeSection/AboutmeComponent";
import { CoreValueHeading } from "../CoreValuesSection/CoreValueComponent";
import { ReddirectPageHeading, RedirectPageContainer, RedirectPageWrapper } from "./RedirectPageComponents";



function RedirectPage(props){
    React.useEffect(()=>{
        const RedirectionButton = document.getElementById("REDBUTT");
        RedirectionButton.click();
    },[])
    return(
        <RedirectPageContainer  style={{minHeight:window.innerHeight}} >
            <RedirectPageWrapper className="container">
                <ReddirectPageHeading className="row">
                    <div className="col-lg-6">
                        <CoreValueHeading><span className="text-theme">Redirecting</span></CoreValueHeading>
                        <br/>
                        {/* <AboutMeHeading >My Latest Projects</AboutMeHeading> */}
                        <Para>Please wait while you are being redirected to resume page. If auto-redirection fails please <a id="REDBUTT" href={props.to}>click here.</a></Para>
                    </div>  
                </ReddirectPageHeading>
            </RedirectPageWrapper>
        </RedirectPageContainer>
    )
}

export default RedirectPage;