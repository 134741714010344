import styled from "styled-components";

export const IntroContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    padding-bottom: 120px;
    padding-top: 100px;

`

export const IntroWrapper = styled.div`
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    @media screen and (max-width:768px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;        

    }
`

export const ImageWrapper = styled.div`
    width: 286px;
    height: 286px;
    padding: 7px;
    border-radius: 50%;
    background-image: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);

    @media screen and (max-width:768px){
        width: 236px;
        height: 236px;
    }

`
export const Image = styled.img`
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 50%;
    object-fit:cover;
`

export const TextWrapper = styled.div`
    padding:0px 80px 12px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width:768px){
        justify-content: center;
        align-items: center;
        text-align: center;
        width: calc(100% - 40px);
        padding: 0;
        padding-top: 30px;
    }
`

export const SubHeading = styled.h6`
    font-size: 1rem;
    margin:0px;
    padding:0px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-family:sans-serif;

`

export const Heading = styled.h2`
    margin:0px;
    padding:0px;
    font-weight: 600;
    color: #fff;
    font-size: 40px;
    font-family:sans-serif;
    
`



export const GradientHeading = styled.span`
    margin:0px;
    padding:0px;
    font-weight: 600;
    font-size: 40px;
    background: linear-gradient(to right, #12c2e9, #f64f59, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
    font-family:sans-serif;
    max-width: min-content;

`



export const Para = styled.p`
    color: rgba(174,174,178,1);
    font-size: 18px;
    max-width: 550px;
    font-weight: lighter;
    font-family:sans-serif;
    line-height: 1.2;

`