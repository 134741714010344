import React from "react";
import { BrowseResumeButton, ButtonWrapper, Icon } from "../AboutmeSection/AboutmeComponent";
import { GradientHeading, Heading, Image, ImageWrapper, IntroContainer, IntroWrapper, Para, SubHeading, TextWrapper } from "./IntroductionSectionComponent";




function IntroductionSection(){
    const [screenSize, getDimension] = React.useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      });
    // const [texts,setTexts]=React.useState([
    //     "Researcher",
    //     "Developer"
    // ])
    // const [TextPlaceHolder,setTextPlaceHolder] = React.useState(texts[0])

    const setDimension = () => {
        getDimension({
          dynamicWidth: window.innerWidth,
          dynamicHeight: window.innerHeight
        })
    }
    const textArray = React.useMemo(() => ["Researcher.", "Developer.", "Inventor.","Thinker.", "Adventurer."], []);
    const typingDelay = 200;
    const erasingDelay = 100;
    const newTextDelay = 2000; // Delay between current and next text
    let charIndex = 0;
    // let textArrayIndex = 0;
    let textArrayIndex = 0;
    const [typedTextSpan,settypedTextSpan] = React.useState(null)
    const [cursorSpan,setcursorSpan] = React.useState(null)
    // const typedTextSpan = document.getElementById("GradientHeading");
    // const cursorSpan = document.getElementById("cursor");
    let erase;
    const type=React.useCallback(()=> {
        if (charIndex < textArray[textArrayIndex].length) {
          if(!cursorSpan.classList.contains("typing")) cursorSpan.classList.add("typing");
          typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
          charIndex++;
          setTimeout(type, typingDelay);
        } 
        else {
          cursorSpan.classList.remove("typing");
            setTimeout(erase, newTextDelay);
        }
    },[cursorSpan,charIndex,erase,textArray,textArrayIndex,typedTextSpan])

    erase=React.useCallback(()=> {
            if (charIndex > 0) {
            if(!cursorSpan.classList.contains("typing")) cursorSpan.classList.add("typing");
            typedTextSpan.textContent = textArray[textArrayIndex].substring(0, charIndex-1);
            charIndex--;
            setTimeout(erase, erasingDelay);
        } 
        else {
            cursorSpan.classList.remove("typing");
            textArrayIndex++;
            if(textArrayIndex>=textArray.length){
                // eslint-disable-next-line
                textArrayIndex=0;
            }
            setTimeout(type, typingDelay + 1100);
        }
    },[cursorSpan,charIndex,type,erase,textArray,textArrayIndex,typedTextSpan])

    const [initalized,setinitialized]=React.useState(false)
    const startTyping = React.useCallback(() => {
        if(initalized===false){
            setTimeout(()=>{
                setinitialized(true);
                type();
            }, newTextDelay + 250);
        } 
    }, [type,initalized,setinitialized]);

    React.useEffect(() => {
        settypedTextSpan(document.getElementById("GradientHeading"));
        setcursorSpan(document.getElementById("cursor"));
        startTyping()
        window.addEventListener('resize', setDimension);


        return(() => {
            window.removeEventListener('resize', setDimension);
        })



    }, [screenSize,settypedTextSpan,setcursorSpan,startTyping])

 




   
    return(
        <IntroContainer id="Home" style={{height:screenSize.dynamicHeight>823?screenSize.dynamicHeight:823}}>
            <IntroWrapper>
                <ImageWrapper>
                    <Image src="https://firebasestorage.googleapis.com/v0/b/venishpatidar-aea3b.appspot.com/o/WhatsApp%20Image%202023-07-13%20at%2021.42.56.jpg?alt=media&token=aed0c141-233b-43a3-8797-0144899d821c" />
                </ImageWrapper>

            {/* <div style={{bottom:0,position:"absolute"}}>
                <img style={{height:780,objectFit:"cover"}}  src="IMG.png"/>
            </div> */}
                <TextWrapper>
                    <SubHeading>Hi there !</SubHeading>
                    <Heading>I'm Venish Patidar</Heading>
                    <Heading><GradientHeading >a Creative </GradientHeading><GradientHeading class="typed-text" id="GradientHeading"></GradientHeading><span className="caret-blink" id="cursor" style={{color:'transparent',margin:0,padding:0,borderWidth:1,borderColor:'white',borderStyle:'solid'}}>|</span></Heading>
                    <Para>I think, sketch plans, and convert my imagination into existence. What helps me succeed is a strong belief in myself and a refusal to give up.</Para>
                    <ButtonWrapper className="row">
                        <BrowseResumeButton href="./Resume">
                            Browse Resume
                            <Icon />
                        </BrowseResumeButton>
                    </ButtonWrapper>
                </TextWrapper>
                
            </IntroWrapper>
        </IntroContainer>
        
    )
}
export default IntroductionSection;