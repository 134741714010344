import React from "react";
import { AboutMeHeading, Para, Span } from "../AboutmeSection/AboutmeComponent";
import { BoxWrapper, BoxContainer, BoxContainerWrapper, MySkillContainer, MyskillHeadingWrapper,MyskillWrapper, SecondSectionWrapper, Box, BoxText, BoxIcon1, BoxIcon3, BoxIcon2, BoxIcon4 } from "./MySkillComponents";


function MySkillComponent(){

    const [offset, setOffset] = React.useState(0);

    React.useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);


    return(
        <MySkillContainer >
            <MyskillWrapper className="container">
                <MyskillHeadingWrapper className="row">
                    <div className="col-lg-6">
                        <AboutMeHeading><Span>My Skills</Span> </AboutMeHeading>
                        <Para>My skillset has a knack for a challenge, and my abilities thrive on difficult tasks.</Para>
                    </div>
                </MyskillHeadingWrapper>

                <SecondSectionWrapper  className="row">

                    <div className="col-lg-6" bis_skin_checked="1">
                        <div className="skill-lt" bis_skin_checked="1">
                            <h6 className="dark-color">Programming Languages</h6>
                            <div className="skill-bar" bis_skin_checked="1">
                                <div className="skill-bar-in theme-bg-g" role="progressbar" aria-valuenow="92" aria-valuemin="0" aria-valuemax="100" bis_skin_checked="1" style={{width: offset<1300?"0%":"90%"}}>
                                    <span>{offset<1300?"":"90%"}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="skill-lt" style={{marginTop:30}} bis_skin_checked="1">
                            <h6 className="dark-color">Fullstack web development</h6>
                            <div className="skill-bar" bis_skin_checked="1">
                                <div className="skill-bar-in theme-bg-g" role="progressbar" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100" bis_skin_checked="1" style={{width: offset<1350?"0%":"95%"}}>
                                    <span>{offset<1350?"":"95%"}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="skill-lt" style={{marginTop:30}} bis_skin_checked="1">
                            <h6 className="dark-color">Technical Skills</h6>
                            <div className="skill-bar" bis_skin_checked="1">
                                <div className="skill-bar-in theme-bg-g" role="progressbar" aria-valuenow="86" aria-valuemin="0" aria-valuemax="100" bis_skin_checked="1" style={{width: offset<1450?"0%":"86%"}}>
                                    <span>{offset<1450?"":"86%"}</span>
                                </div>
                            </div>
                        </div>
                        <div className="skill-lt" style={{marginTop:30}} bis_skin_checked="1">
                            <h6 className="dark-color">Communication skills</h6>
                            <div className="skill-bar" bis_skin_checked="1">
                                <div className="skill-bar-in theme-bg-g" role="progressbar" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100" bis_skin_checked="1" style={{width: offset<1550?"0%":"86%"}}>
                                    <span>{offset<1550?"":"86%"}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BoxContainer className="col-lg-5">
                        <BoxContainerWrapper className="row gy-4">

                            <BoxWrapper className="col-6">
                                <Box>
                                    <BoxIcon1/>
                                    <BoxText>30+ Projects Completed</BoxText>
                                </Box>
                            </BoxWrapper>

                            <BoxWrapper className="col-6">
                                <Box>
                                    <BoxIcon2/>
                                    <BoxText>2 Awards Won</BoxText>
                                </Box>
                            </BoxWrapper>

                            <BoxWrapper className="col-6">
                                <Box>
                                    <BoxIcon3/>
                                    <BoxText>3 Years of Experience</BoxText>
                                </Box>
                            </BoxWrapper>

                            <BoxWrapper className="col-6">
                                <Box>
                                    <BoxIcon4/>
                                    <BoxText>1 Book Published</BoxText>
                                </Box>
                            </BoxWrapper>


                        </BoxContainerWrapper>

                    </BoxContainer>

                </SecondSectionWrapper>

            </MyskillWrapper>
        </MySkillContainer>
    )
}

export default MySkillComponent;