import styled from "styled-components";

export const CoreValueContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
`
export const CoreValueWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
`
export const CoreValueHeadingWrapper = styled.div`
    padding-bottom: 50px;
    justify-content: center;
    text-align: center;
`
export const CoreValueHeading = styled.h6`
    display: inline-flex;
    padding: 8px 15px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    margin-bottom: 15px;
`

export const SecondContainer = styled.div`

`

export const BoxWrapper = styled.div`

`

export const CoreValueBox = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 15px;
    padding: 35px 25px;
    transition: ease all 0.35s;
`
export const IconContainer = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    width: 80px;
    height: 80px;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
    font-size: 40px;
    color: rgba(174,174,178,1);
 
`
export const BoxDetailWrapper = styled.div`
`
export const BoxHeading = styled.h5`
    font-size: 1.25rem;
    color: white;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
`


