import styled from "styled-components";

export const PortfolioSectionContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
`

export const PortfolioSectionWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
`
export const PortfolioHeadingWrapper = styled.div`
    padding-bottom: 50px;

`

export const BoxContainer = styled.div`
    
`

export const BoxOwlStageOuter = styled.div`

`
export const BoxStage = styled.div`

`
export const BoxWrapper = styled.div`
    
`

export const Box = styled.div`
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 30px;
`

export const ImageBox = styled.div`
    overflow: hidden;
    width: 100%;
    height: 250px;
`

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ease all 0.35s;
    :hover{
        scale: 1.1;
    }

`
export const TextDetailContainer = styled.div`
    padding: 15px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const TextHeading = styled.h5`
    color: #fff;
    margin: 5px 0 15px;
`
export const TextPara = styled.p`
    color:rgba(174,174,178,1);
    line-clamp:5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 5; 
    -webkit-box-orient: vertical;
`
export const BtnContainer = styled.div`
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 3px;
    background: none;
    font-size: 12px;
    border: none;
    width: fit-content;
`
export const BtnA = styled.a`
    text-decoration: none;
    color: rgba(199, 199, 204, 1);
    cursor: pointer;
    :hover{
        color: white;
    }
    :hover:after {
        right: auto;
        left: 0;
        width: calc(100% - 31px);
    }
    :after {
        content: "";
        position: absolute;
        bottom: 0;
        left: auto;
        right: 31px;
        height: 2px;
        width: 0;
        background-image: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
        transition: ease all 0.35s;
    }
`
export const SpanUnderLine = styled.span`
   
`
