import styled from "styled-components";

export const RenderProjectContainer= styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
`

export const RenderProjectWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
`

export const RenderProjectPageHeading = styled.div`
`

export const ImageContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width:768px){
        margin-bottom:20px
    }
`


export const Image = styled.img`
`

