import React from "react";
import { Container, LeftEffect, RightEffect } from "./BackgroundComponent";

function Background(){
    const [screenSize, getDimension] = React.useState({
        dynamicHeight: window.innerHeight
      });
      const setDimension = () => {
        getDimension({
          dynamicHeight: window.innerHeight
        })
      }
      
      React.useEffect(() => {
        window.addEventListener('resize', setDimension);
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])
    return(
        <Container style={{height:screenSize.innerHeight}}>
            <RightEffect/>
            <LeftEffect/>
        </Container>
    )
}

export default Background;