import React, { Component } from "react";
import { AboutMeHeading, Para } from "../AboutmeSection/AboutmeComponent";
import { CoreValueHeading } from "../CoreValuesSection/CoreValueComponent";
import { Image, ImageContainer, RenderProjectContainer, RenderProjectPageHeading, RenderProjectWrapper } from "./ProjectDescriptionComponent";
import { collection,getDocs,query,where } from "firebase/firestore";
import { firestore } from "../../firebase/config";
class RenderProjectDetails extends Component{
    constructor(props){
        super(props)
        this.state={
            dynamicHeight:0,
            content:'',
            category:'',
            title:'',
            description:'',
            src:''
        }
    }
    getPortfolio = async ()=>{
        const docRef = collection(firestore, "Portfolio");
        const q = query(docRef, where('slug',"==",this.props.slug));
        const docsSnap = await getDocs(q);
        const temp = {
            content:'',
            category:'',
            title:'',
            description:'',
            src:''
        }
        docsSnap.forEach(doc => {
            temp.content=doc.data().content;
            temp.category=doc.data().category;
            temp.title=doc.data().title;
            temp.description=doc.data().description;
            temp.src=doc.data().src;
        })
        this.setState({
            content:temp.content,
            category:temp.category,
            title:temp.title,
            description:temp.description,
            src:temp.src,
        })
        document.title="Venish Patidar - "+temp.title
        document.querySelector('meta[name="description"]')
        .setAttribute('content', temp.description);
    }


    componentDidMount(){
        this.getPortfolio();
    }



    
    render(){
        return(
            <RenderProjectContainer style={{minHeight:window.innerHeight}}  >
                <RenderProjectWrapper className="container">
                    <RenderProjectPageHeading >

                        <div className="row">
                        <ImageContainer className="col-lg-6 ">
                            <Image style={{width:"100%",objectFit:'cover'}} src={this.state.src} />
                        </ImageContainer>
                         
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}} className="col-lg-6" >
                                <CoreValueHeading style={{width:'fit-content'}}><span className="text-theme">{this.state.category}</span></CoreValueHeading>
                                <br/>
                                <AboutMeHeading >{this.state.title}</AboutMeHeading>

                                <Para style={{textAlign:"justify"}} >{this.state.description}</Para>
                            </div>  
                        </div>
                    </RenderProjectPageHeading>
                    
                    <Para style={{textAlign:"justify"}} >{this.state.content}</Para>

                    
                </RenderProjectWrapper>
            </RenderProjectContainer>
        )
    }
    
}

export default RenderProjectDetails;