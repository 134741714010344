import React from "react";
import RenderProjectDetails from "../Components/ProjectDescription";
import { useParams } from "react-router-dom";



function ProjectDescriptionPage(){
    let { slug } = useParams(); 
    return(
            <div style={{display:"flex",flex:1,flexDirection:'column'}}>
                <RenderProjectDetails slug={slug} />
                
            </div>
    )
}

export default ProjectDescriptionPage;
