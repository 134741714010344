import React from "react";
import AboutMeSection from "../Components/AboutmeSection";
// import BlogSection from "../Components/BlogSection";
import ContactUs from "../Components/ContactusSection";
import CoreValue from "../Components/CoreValuesSection";
import IntroductionSection from "../Components/IntroductionSection";
import MySkillComponent from "../Components/MyskillSection";
import PortfolioSection from "../Components/PortfolioSection";


function Homepage(){
    document.title="Venish Patidar";
    document.querySelector('meta[name="description"]')
    .setAttribute('content', "Mr. Venish Patidar is an Indian computer scientist, researcher (with a focus on AI), and technology entrepreneur. He is the founder and CEO of VNET, a firm that specializes in web development, software engineering and IoT.");
    return(
            <div style={{display:"flex",flex:1,flexDirection:'column'}}>
                <IntroductionSection  />
                <AboutMeSection />
                <MySkillComponent/>
                <PortfolioSection  />
                <CoreValue />
                {/* <BlogSection /> */}
                <ContactUs/>
            </div>
    )
}

export default Homepage
