import React from "react";
import ProjectsMainPage from "../Components/ProjectsPage";



function ProjectsPage(){
    return(
            <div style={{display:"flex",flex:1,flexDirection:'column'}}>
                <ProjectsMainPage  />
                
            </div>
    )
}

export default ProjectsPage;
