import styled from "styled-components";

export const ProjectPageContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
`

export const ProjectPageWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
`

export const ProjectPageHeading = styled.div`
    padding-bottom: 50px;
    justify-content: center;
    text-align: center;
`
export const BoxContainer = styled.div`

`

export const BoxWrapper = styled.div`

`

export const CategoryContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
    
    
`
export const CategoryList = styled.ul`
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: auto; 
    text-align: left;
    flex-wrap: wrap;
    justify-content: center;

`

export const CategoryLi = styled.li`
    margin-right: 10px;
`
export const CategoryliBtn = styled.div`
    text-decoration: none;
    white-space: nowrap ;
    color:${({active})=>(active?"white":"rgba(199, 199, 204, 1)")};
    cursor: pointer;
    :hover{
        color: white;
    }
    :hover:after {
        right: auto;
        left: 0;
        width: 100%;
    }
    :after {
        content: "";
        position: absolute;
        bottom: 0;
        left: auto;
        right: 0px;
        height: 2px;
        width: ${({active})=>(active?"100%":"0")};
        background-image: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
        transition: ease all 0.35s;
    }
`