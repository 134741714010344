import styled from "styled-components";
import {FcRating, FcApproval,FcReading,FcEngineering} from "react-icons/fc"
export const MySkillContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;

`
export const MyskillWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);

`

export const MyskillHeadingWrapper = styled.div`
    padding-bottom: 50px;

`

export const SecondSectionWrapper = styled.div`


`

export const BoxContainer = styled.div`


`

export const BoxContainerWrapper = styled.div`


`

export const BoxWrapper = styled.div`
   @media screen and (max-width:768px){
        margin-top: 60px;
    }   
`

export const Box = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 15px;
    padding: 25px;
    transition: ease all 0.35s;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    align-items: center;
`

export const BoxIcon1 = styled(FcApproval)`
    font-weight: 900;
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
`
export const BoxIcon2 = styled(FcRating)`
    font-weight: 900;
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
`
export const BoxIcon3 = styled(FcEngineering)`
    font-weight: 900;
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
`
export const BoxIcon4 = styled(FcReading)`
    font-weight: 900;
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
`
export const BoxText = styled.h5`
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 1.25rem;
    padding-left: 10px;
`