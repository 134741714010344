import React from "react";
import { AboutMeHeading, AboutMeHeadingh6, BrowseResumeButton, ButtonWrapper, Icon, Para, Span } from "../AboutmeSection/AboutmeComponent";
import { Box,  BoxText, BoxWrapper } from "../MyskillSection/MySkillComponents";
import { ContactUsContainer, ContactUsHeadingContainer, ContactUsWrapper, ContactWrapper, DetailBox, DetailBoxWrapper, InputStyle, SecondContainer, TextAreaStyle } from "./ContactusSectionComponent";
import {FaLocationArrow} from 'react-icons/fa'
import {IoMdCall} from 'react-icons/io'
import {MdEmail} from 'react-icons/md'
function ContactUs(){
    return(
        <ContactUsContainer id="Contact">
            <ContactUsWrapper className="container">
                <ContactUsHeadingContainer className="row">
                    <div className="col-lg-6">
                        <AboutMeHeading><Span>Get in touch</Span> </AboutMeHeading>
                        <Para>Feel free to email me if you have any questions or just want to discuss anything related to computer science, mathematics, or life.</Para>
                    </div>
                </ContactUsHeadingContainer>
                <SecondContainer className="row">
                    <DetailBoxWrapper className="col-lg-7 col-xl-8">
                        <DetailBox>
                        <div>
                            <form id="contact-form" method="POST">
                                <AboutMeHeadingh6>SAY SOMETHING</AboutMeHeadingh6>
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <InputStyle name="Name" id="name" placeholder="Name *" className="form-control" type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <InputStyle name="Email" id="email" placeholder="Email *" className="form-control" type="email"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <InputStyle name="Subject" id="subject" placeholder="Subject *" className="form-control" type="text"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <TextAreaStyle name="message" id="message" placeholder="Your message *" rows="3" className="form-control"></TextAreaStyle>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="send">
                                            <ButtonWrapper style={{paddingTop:10}} className="row">
                                                <BrowseResumeButton href="">
                                                    Send Message
                                                    <Icon />
                                                </BrowseResumeButton>
                                            </ButtonWrapper>
                                            {/* <span id="suce_message" className="text-success" >Message Sent Successfully</span>
                                            <span id="err_message" className="text-danger" >Message Sending Failed</span> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </DetailBox>
                    </DetailBoxWrapper>
                    <ContactWrapper className="col-lg-5 col-xl-4">
                            <BoxWrapper className="row"> 
                                <Box>
                                    <div style={{backdropFilter: "saturate(180%) blur(20px)",fontSize:24,width:60,height:60,flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:15,textAlign:'center',border:'1px solid rgba(255, 255, 255, 0.1)'}}>
                                        <FaLocationArrow style={{color:"#fff",}} />
                                    </div>
                                    <BoxText style={{fontSize:"1rem"}}>100, mg Road, Kukshi 454331 Madhya Pradesh</BoxText>
                                </Box>
                            </BoxWrapper>
                            <BoxWrapper className="row"> 
                                <Box>
                                    <div style={{backdropFilter: "saturate(180%) blur(20px)",fontSize:24,width:60,height:60,flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:15,textAlign:'center',border:'1px solid rgba(255, 255, 255, 0.1)'}}>
                                        <IoMdCall style={{color:"#fff",}} />
                                    </div>
                                    <BoxText style={{fontSize:"1rem"}}><a style={{textDecorationLine:'none',color:'white'}} href="tel:+918319874206">+91-831-9874206</a></BoxText>
                                </Box>
                            </BoxWrapper>
                            <BoxWrapper className="row"> 
                                <Box>
                                    <div style={{backdropFilter: "saturate(180%) blur(20px)",fontSize:24,width:60,height:60,flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:15,textAlign:'center',border:'1px solid rgba(255, 255, 255, 0.1)'}}>
                                        <MdEmail style={{color:"#fff",}} />
                                    </div>
                                    <BoxText style={{fontSize:"1rem"}}><a style={{textDecorationLine:'none',color:'white'}} href="mailto:venishpatidar@gmail.com">venishpatidar@gmail.com</a></BoxText>
                                </Box>
                            </BoxWrapper>
                    </ContactWrapper>

                </SecondContainer>
            </ContactUsWrapper>
        </ContactUsContainer>
    )
}

export default ContactUs;