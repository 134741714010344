import React from 'react';
import Navbar, { ProjectNavBar } from '../Navbar';
// import Sidebar from '../SideBar';

export default function Header(props) {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = ()=>{
        setIsOpen(!isOpen);
    }
    const [homenavbar,sethomenavbar]=React.useState(true);

    React.useEffect(()=>{
        console.log(window.location.href)
        if(window.location.href.search("Projects")!==-1){
            sethomenavbar(false)
        }
    },[sethomenavbar])
    if(!homenavbar){
        return(
            <ProjectNavBar />
        )
    }
    return (
        <>
            {/* <Sidebar {...props} isOpen={isOpen} toggle={toggle} /> */}
            <Navbar {...props} toggle={toggle} />
        </>
    )
}
