import React from "react";
import { FooterContainer, FooterWrapper, Name } from "./FooterComponent";

function Footer(){
    const year = new Date().getFullYear()
    return(
        <FooterContainer>
            <FooterWrapper>
                <Name>Venish Patidar © {year}</Name>
                <div style={{display:"flex"}}>
                    <Name>Latest Post-</Name>
                    <Name>-Instagram</Name>
                </div>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer;