import React from "react";
import {Nav,NavLink,NavMenu,Bars, Name,} from './NavbarComponent';
import {animateScroll as scroll} from 'react-scroll';
import { useNavigate } from "react-router-dom";

const Navbar = (props) => {
    const [ScrollVal ,SetScrollVal] = React.useState(false);
    const changeNave = ()=>{
        if(window.scrollY>=80){
            SetScrollVal(true)
        }
        else{
            SetScrollVal(false)
        }
    }
    React.useEffect(() => {
        window.addEventListener('scroll',changeNave)
    }, [])
    const toggleHome = ()=>{
        scroll.scrollToTop();
    }
    return (
        <Nav scrollNav={ScrollVal}>
                <Name onClick={toggleHome} >Venish Patidar</Name>
            <Bars  onClick={props.toggle} />
            <NavMenu>
                <NavLink to="Home" activeStyle smooth={true} duration={500} spy={true} exact="true" offset={-70}>Home</NavLink>
                <NavLink to="About me" activeStyle smooth={true} duration={500} spy={true} exact="true" offset={-70}>About me</NavLink>
                <NavLink to="Portfolio" activeStyle smooth={true} duration={500} spy={true} exact="true" offset={-70}>Portfolio</NavLink>
                <NavLink to="Values" activeStyle smooth={true} duration={500} spy={true} exact="true" offset={-70}>Values</NavLink>
                <NavLink to="Contact" activeStyle smooth={true} duration={500} spy={true} exact="true" offset={-70}>Contact</NavLink>
                
            </NavMenu>
            
        </Nav>
    );
}

export default Navbar


export const ProjectNavBar = (props) => {
    const [ScrollVal ,SetScrollVal] = React.useState(false);
    const navigate = useNavigate();

    const changeNave = ()=>{
        if(window.scrollY>=80){
            SetScrollVal(true)
        }
        else{
            SetScrollVal(false)
        }
    }
    React.useEffect(() => {
        window.addEventListener('scroll',changeNave)
    }, [])
   
    return (
        <Nav scrollNav={ScrollVal}>
                <Name onClick={()=>{navigate(-1)}} >Venish Patidar</Name>
            <Bars  onClick={props.toggle} />
            <NavMenu>
                <NavLink onClick={()=>{
                    navigate(-1)
                }} offset={-70}>   back</NavLink>
            </NavMenu>
            
        </Nav>
    );
}
