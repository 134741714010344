import React from "react";
import RedirectPage from "../Components/RedirectPage";

function ResumePage(){
    return(
        <RedirectPage to="https://firebasestorage.googleapis.com/v0/b/venishpatidar-aea3b.appspot.com/o/Resume%20V4.pdf?alt=media&token=886d3c80-d873-415c-bb66-244712096dce" />
    )
}

export default ResumePage;
  