import styled from "styled-components";

export const RedirectPageContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
`

export const RedirectPageWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
    z-index: 10;

`

export const ReddirectPageHeading = styled.div`
    padding-bottom: 50px;
    justify-content: center;
    text-align: center;
`