import styled from "styled-components";

export const FooterContainer = styled.footer`
    background:#000;
    height:56px;
    display:flex;
    justify-content:space-evenly; 
    padding:0.5rem calc((100vw-1000px)/2);
    font-family: "Rubik", sans-serif;
    z-index:10;
    top:0;
    position:sticky;
    width:100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
`
export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: space-around;
    padding: 8px;
`
export const Name = styled.p`
    align-self: center;
    font-size: 14px;
    color: rgba(174,174,178,1);
    cursor: pointer;
    :hover{
        color: white;
    }
`
