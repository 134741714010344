import styled from "styled-components";

export const ContactUsContainer = styled.div`
    display: flex;
    width: 100%;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;
`

export const ContactUsWrapper = styled.div`
    padding-top: 100px;
    overflow :hidden ;
    border-style: solid;
    border-width: 0;
    border-top-width: 1px;
    border-color: rgba(100,100,100,0.5);
`

export const ContactUsHeadingContainer = styled.div`
    padding-bottom: 50px;

`
export const SecondContainer = styled.div`

`
export const DetailBox = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 15px;
    padding: 35px;

`
export const DetailBoxWrapper = styled.div`

`
export const InputStyle = styled.input`
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.775rem 1.05rem;
    box-shadow: none;
    color: #fff;
    :focus{
        background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.775rem 1.05rem;
    box-shadow: none;
    color: #fff;
    }
    
`
export const TextAreaStyle = styled.textarea`
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.775rem 1.05rem;
    box-shadow: none;
    color: #fff;
    :focus{
        background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.775rem 1.05rem;
    box-shadow: none;
    color: #fff;
    }
`

export const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left:20px;
    padding-right: 20px;
    @media screen and (max-width:990px){
        margin-top: 60px;
    }  
    @media screen and (max-width:768px){
    }  
`