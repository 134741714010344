import React from "react";
import { Routes, Route, Navigate, } from 'react-router-dom';
import Background from "./Components/Background";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Homepage from "./Pages/Homepage";
import ProjectDescriptionPage from "./Pages/ProjectDescriptionPage";
import ProjectsPage from "./Pages/ProjectsPage";
import ResumePage from "./Pages/ResumePage";

const Temp = ()=>{
    return(
        <div id="Home" style={{display:'flex',flex:1,height:'1px'}}></div>
    )
}

class Navigator extends React.Component{
    
    render(){
        return(
            <div className="App">
                <Header/>
                    <Routes>
                        <Route path='/' element={<Homepage/>} />
                        <Route path='/Resume' element={<ResumePage />} />
                        <Route path='/Projects' element={<ProjectsPage/>} />
                        <Route path='/Projects/:slug'  element={<ProjectDescriptionPage/>} />
                        <Route path='/404' element={<Temp/>} />
                        <Route path='*' element={<Navigate to="/404" />} />
                    </Routes>
                <Background/>
                <Footer/>
            </div>
        )
    }
}
export default Navigator;